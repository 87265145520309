import { Loggable, Logger } from '@johnlewispartnership/wtr-website-logger';
import { logger } from 'logger/instance';
import getLoggerRequestContext from 'utils/loggerRequestContext';

const logApi =
  (logFn: Logger['error' | 'fatal' | 'warn' | 'info' | 'debug']) =>
  (input: Loggable & { payload: Loggable['payload'] & { service: string; url: string } }) => {
    const loggerRequestContext = getLoggerRequestContext();
    logFn({
      ...input,
      customerId: loggerRequestContext.customerId,
      clientUserAgent: loggerRequestContext.clientUserAgent,
      ...(loggerRequestContext.clientIpAddress && {
        clientIpAddress: loggerRequestContext.clientIpAddress,
      }),
      payload: {
        logType: 'apiRequest',
        ...input.payload,
        ...(loggerRequestContext.incomingRequestId && {
          requestId: loggerRequestContext.incomingRequestId,
        }),
      },
    });
  };

export const logApiError = logApi(logger.error);
export const logApiWarning = logApi(logger.warn);
export const logApiInfo = logApi(logger.info);
export const logApiDebug = logApi(logger.debug);
export const logApiFatal = logApi(logger.fatal);
